import { createReducer, on } from "@ngrx/store";
import * as ownerActions from "./owner.actions";
import * as _ from "lodash";

export interface OwnerStateModel {
  initialState: boolean,
  success: boolean,
  errorCode: string,
  errorMessage: string
  owner: any
}

export const ownerInitialState: OwnerStateModel = {
  initialState: true,
  success: true,
  errorCode: '',
  errorMessage: '',
  owner: null
};

export const ownerReducer = createReducer(
  ownerInitialState,
  on(ownerActions.loadOwner, (state) => state),
  on(ownerActions.getOwner, (state, { owner }) => ({
    ..._.cloneDeep(state),
    initialState: false,
    success: true,
    errorCode: '',
    errorMessage: '',
    owner
  })),
  on(ownerActions.getOwnerError, (state, { errorCode, errorMessage }) => ({
    ..._.cloneDeep(state),
    initialState: false,
    success: false,
    errorCode,
    errorMessage
  }))
);
