import { createAction, props } from "@ngrx/store";

export const LOAD_OWNER = 'Load Owner';
export const GET_OWNER = 'Get Owner';
export const GET_OWNER_ERROR = 'Get Owner Error';

export const loadOwner = createAction(LOAD_OWNER);
export const getOwner = createAction(GET_OWNER,
  props<{ owner: any }>());
export const getOwnerError = createAction(GET_OWNER_ERROR,
  props<{ errorCode: string, errorMessage: string }>());
